import { Box } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import logo from '../images/logo.png';

export function Header() {
    return (
      <React.Fragment>
      <Container maxWidth="md" component="header">
        <Box py={4} >
          <img src={logo} width="150px" alt="Logo Parseint"/>
        </Box>
      </Container>

      </React.Fragment>
    );
  }
  