import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Link, Typography } from "@mui/material";
import React, { Fragment } from "react";
import CssBaseline from '@mui/material/CssBaseline';

import { Header } from "../components/Header";
import { Container } from "@mui/system";
import rocket from '../images/rocket.png';

import sischefTitle from '../images/sischef-title.png';
import qrpedirTitle from '../images/qrpedir-title.png';

const footers = [
  {
    title: 'Empresa',
    description: [
      { label: 'Contato', link: "https://sischef.com/contato"}, 
      { label: 'Trabalhe conosco', link: "https://sischef.com/vagas"},
      { label: 'Sobre nós', link: "https://sischef.com/sobre-nos"} ],
  },
  {
    title: 'Produtos',
    description: [{ label: 'Sischef', link: "https://sischef.com"}, { label: 'qrpedir', link: "https://qrpedir.com"}, { label: 'Sisagil', link: "https://sisagil.com"} ],
  },
  {
    title: 'Suporte',
    description: [{ label: 'Ajuda Sischef', link: "https://sischef.space"}, { label: 'Ajuda Qrpedir', link: "https://qrpedir.com/suporte/"} ],
  },
  {
    title: 'Comercial',
    description: [
      { label: 'Cadastro Sischef', link: "https://parseint.com.br/cadastro/sischefCadastro.html"}, 
      { label: 'Cadastro Qrpedir', link: "https://parseint.com.br/cadastro/qrpedirCadastro.html"}, 
      { label: 'Cadastro Sisagil', link: "https://parseint.com.br/cadastro/sisagilCadastro.html"}
      ],
  },
  {
    title: 'Informações',
    description: [{ label: 'Status APIs', link: "https://stats.uptimerobot.com/Gv0vNHBNnN"} ],
  },
];

export function Home() {




    return (
      <React.Fragment>
      <CssBaseline/>
      <Header/>
      
      <Box py={10} sx={{
        backgroundColor: '#f5f5f5',
      }}>
      <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid md={8} xs={12}>
              <Typography variant="h4" component="h1"  gutterBottom>
              Produtos perfeitos para seu negócio
              </Typography>
  
              <Typography component="h1"  variant="h6"  >
              Que tal conhecer nossos produtos um pouco melhor?
              </Typography>
              </Grid>

              <Grid md={4} xs={12}>

                <img src={rocket} width="350px" alt="Foguete da Parseint"/>
              </Grid>
            </Grid>
           
          </Container>  
          </Box>

          <Box py={5} sx={{
          }}>
          <Container maxWidth="md" component="main">
            <Typography variant="h4" component="h1"  gutterBottom>
             Nossos produtos
            </Typography>

            <Grid container spacing={5} >
              <Grid
                item
                xs={12}
                md={6}
              >
              
                <BasicCard
                title="Sischef"
                subtitle="O Sischef é um sistema para automação e gestão de restaurantes, bares, cafés, fast-foods e deliveries"
                image={sischefTitle}
                url='https://sischef.com'
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <BasicCard
                title="qrpedir"
                subtitle="Cardápio Digital para Bares e Restaurantes - Delivery e salão"
                image={qrpedirTitle}
                url='https://qrpedir.com'
                />
              </Grid>
            
            </Grid>
           
          </Container>
          </Box>

          {/* Footer */}
            <Container
            maxWidth="md"
            component="footer"
            sx={{
              borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              mt: 8,
              py: [3, 6],
            }}
          >
            <Grid container spacing={4} justifyContent="space-evenly">
              {footers.map((footer) => (
                <Grid item xs={6} sm={3} key={footer.title}>
                  <Typography variant="h6" color="text.primary" gutterBottom>
                    {footer.title}
                  </Typography>
                  <ul>
                    {footer.description.map((item) => (
                      <li key={item.link}>
                        <Link href={item.link} target="_blank" variant="subtitle1" color="text.secondary">
                          {item.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Grid>
              ))}
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Container>
          {/* End footer */}


      </React.Fragment>
    );
  }
  

  function BasicCard({title, subtitle, url, image,  callToAction}) {
    return (
      <Card sx={{ maxWidth: 375 }} variant="outlined">
      <CardActionArea  href={url} target="_blank">
        <CardMedia
            component="img"
            image={image}
            alt={title}
        />
        <CardContent>
          
          <Typography variant="h5" component="div">
           {title}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
         {subtitle}
          </Typography>
        </CardContent>
        <CardActions>
          <Button href={url} target="_blank">Saiba mais</Button>
        </CardActions>
        </CardActionArea>
      </Card>
    );
  }


  function Copyright(props) {
    return (
      <Fragment>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Parseint Desenvolvimento de Sistemas Ltda - 
      CNPJ: 13.160.631/0001-10
     </Typography>
     
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://parseint.com.br">
         Parseint.com.br
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
     
     </Fragment>
    );
  }
  