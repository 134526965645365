import React from 'react';
import {
  useParams
} from "react-router-dom";

function Cadastros() {

  let { page } = useParams();

  if (page){
    window.location.href = `https://site.parseint.com.br/cadastro/${page}`;
  }

  return (
    <React.Fragment>
      <div>Carregando... {page}</div>
    </React.Fragment>
  );
}

export default Cadastros;
